.slideshow{
	position: relative;
	z-index: -1;
	margin: -264px 0 0;
	width: 100%;
	height: 100%;
	min-height: 768px;
	overflow: hidden;
	background-color: $color-1;

	ul{
		display: flex;
		padding: 0;
		margin: 0;
		width: 100%;
		height: 100%;
	}

	li{
		width: 100%;
		height: 100%;
		display: flex;
	}

	.flex-viewport{
		display: flex;
		width: 100%;
		height: 100%;
	}
}

.slideshow__slide{
	display: flex;
	align-items: flex-end;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
}

.slide__content{
	position: relative;
	z-index: 1;
	width: 100%;
	padding: 120px 0;
	text-align: left;
	color: white;

	.wysi{
		padding: 30px 70px;
		border-left: 2px solid white;
	}

	h1{
		font-size: rem(72);
	}

	h2{
		font-size: rem(60);
	}

	h3{
		font-size: rem(24);
	}

	h1,
	h2,
	h3{
		color: white;
	}
}

.slide__image{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-position: center center;
	background-size: cover;
}

.animated .slide__image{
	transform: scale(1.025);
	transition: transform 15s ease;
}

.slideshow__control{
	z-index: 99;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 40px;
	height: 76px;
	overflow: hidden;
	text-indent: -999em;
}

.slideshow__control-icon{
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	width: 40px;
	height: 76px;
	fill: white;
}

.slideshow__control--next{
	right: 30px;
}

.slideshow__control--prev{
	left: 30px;
}

.section--seo{
	padding: 120px 0;
	background-color: white;

	.grid{
		margin: -($gutter*2);
	}

	.col{
		padding: $gutter*2;

		&:last-child{
			align-self: flex-start;
		}
	}
}

.section--gallery{
	background-color: darken(white, 3);
	padding: 120px 0;

	.grid{
		align-items: flex-start;
	}

	.hidden{
		display: none;
	}
}

.gallery__image{
	display: block;
	overflow: hidden;
	position: relative;

	img{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&--vertical{
		width: 100%;
		height: 0;
		padding-bottom: 150%;
	}

	&--horizontal{
		width: 100%;
		height: 0;
		padding-bottom: calc(75% - #{$gutter/2});

		img{
			max-width: none;
			max-height: 100%;
		}
	}

	&:before{
		content: '';
		display: block;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: black;
		opacity: 0;
		transition: all 0.2s ease;
	}

	&:after{
		content: attr(data-label);
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		z-index: 2;
		top: $gutter;
		bottom: $gutter;
		left: $gutter;
		right: $gutter;
		border: 1px solid white;
		font-size: rem(13);
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: 0.25em;
		color: white;
		opacity: 0;
		transition: all 0.2s ease;
	}

	&:hover{
		&:before{
			opacity: 0.15;
		}

		&:after{
			opacity: 1;
		}
	}
}

.section--contact{
	padding: 120px 0;
	background-color: white;

	h2{
		font-size: rem(48);
	}

	.form{
		margin-top: $gutter*2;
	}

	.grid{
		align-items: flex-start;
		margin: -($gutter*2);
	}

	.col{
		padding: $gutter*2;
	}
}

@media screen and (max-width: 1199px){

}

@media screen and (max-width: 1023px){
	.slide__content{
		padding: 60px 0;
		h1{
			font-size: rem(60);
		}

		h2{
			font-size: rem(48);
		}

		h3{
			font-size: rem(18);
		}
	}

	.section--seo{
		padding: 60px 0;

		.grid{
			margin: -$gutter;
		}

		.col{
			padding: $gutter;
		}
	}

	.section--gallery{
		padding: 60px 0;
	}

	.section--contact{
		padding: 60px 0;

		h2{
			font-size: rem(36);
		}

		.grid{
			margin: -$gutter;
		}

		.col{
			padding: $gutter;

			&:nth-child(1){
				order: 2;
			}

			&:nth-child(2){
				order: 1;
			}
		}
	}
}

@media screen and (max-width: 959px){

}

@media screen and (max-width: 767px){
	.slide__content{
		.wysi{
			padding: 0;
			border: 0;
		}
	}

	.section--gallery{
		> .grid-container{
			> .grid{
				> .col{
					&:nth-child(1){
						order: 1;
					}

					&:nth-child(2){
						order: 3;
					}

					&:nth-child(3){
						order: 2;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 639px){
	.section--gallery{
		> .grid-container{
			> .grid{
				> .col{
					&:nth-child(1){
						order: 1;
					}

					&:nth-child(2){
						order: 2;
					}

					&:nth-child(3){
						order: 3;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 479px){
	
}