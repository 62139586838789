/*
 * Fonts
 */
$base-font-family: 'Montserrat', sans-serif;
$base-font-size: 15px;
$base-font-line-height: 28px;
$base-font-color: #5a5a5a;

$playfair: 'Playfair Display', serif;

/*
 * Colors
 */
$color-1: #191516;
$color-2: #ccc;
$color-3: #fff;

$font-color-1: #191516;
$font-color-2: #5a5a5a;
$font-color-3: #ccc;

$error: #df5555;
$success: #429e62;

/*
 * Gutters
 */
$gutter: 20px;