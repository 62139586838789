/*
 * Theme overrides
 */
 .section--404{
 	width: 100%;
 	height: 100%;
 	display: flex;
 	justify-content: center;
 	align-items: center;
 }

@media screen and (max-width: 1199px){

}

@media screen and (max-width: 1023px){

}

@media screen and (max-width: 959px){

}

@media screen and (max-width: 767px){

}

@media screen and (max-width: 639px){

}

@media screen and (max-width: 479px){
	
}