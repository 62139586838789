/*
 * Fonts
 */
/*
 * Colors
 */
/*
 * Gutters
 */
/*
 * px to rem
 */
.grid-container {
  overflow: hidden; }

.grid-controls {
  margin: 40px 0 0;
  display: flex;
  justify-content: center; }

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: -10px; }

.col {
  flex: 1 0 auto;
  padding: 10px; }
  .col--1 {
    width: 1%; }
  .col--2 {
    width: 2%; }
  .col--3 {
    width: 3%; }
  .col--4 {
    width: 4%; }
  .col--5 {
    width: 5%; }
  .col--6 {
    width: 6%; }
  .col--7 {
    width: 7%; }
  .col--8 {
    width: 8%; }
  .col--9 {
    width: 9%; }
  .col--10 {
    width: 10%; }
  .col--11 {
    width: 11%; }
  .col--12 {
    width: 12%; }
  .col--13 {
    width: 13%; }
  .col--14 {
    width: 14%; }
  .col--15 {
    width: 15%; }
  .col--16 {
    width: 16%; }
  .col--17 {
    width: 17%; }
  .col--18 {
    width: 18%; }
  .col--19 {
    width: 19%; }
  .col--20 {
    width: 20%; }
  .col--21 {
    width: 21%; }
  .col--22 {
    width: 22%; }
  .col--23 {
    width: 23%; }
  .col--24 {
    width: 24%; }
  .col--25 {
    width: 25%; }
  .col--26 {
    width: 26%; }
  .col--27 {
    width: 27%; }
  .col--28 {
    width: 28%; }
  .col--29 {
    width: 29%; }
  .col--30 {
    width: 30%; }
  .col--31 {
    width: 31%; }
  .col--32 {
    width: 32%; }
  .col--33 {
    width: 33%; }
  .col--34 {
    width: 34%; }
  .col--35 {
    width: 35%; }
  .col--36 {
    width: 36%; }
  .col--37 {
    width: 37%; }
  .col--38 {
    width: 38%; }
  .col--39 {
    width: 39%; }
  .col--40 {
    width: 40%; }
  .col--41 {
    width: 41%; }
  .col--42 {
    width: 42%; }
  .col--43 {
    width: 43%; }
  .col--44 {
    width: 44%; }
  .col--45 {
    width: 45%; }
  .col--46 {
    width: 46%; }
  .col--47 {
    width: 47%; }
  .col--48 {
    width: 48%; }
  .col--49 {
    width: 49%; }
  .col--50 {
    width: 50%; }
  .col--51 {
    width: 51%; }
  .col--52 {
    width: 52%; }
  .col--53 {
    width: 53%; }
  .col--54 {
    width: 54%; }
  .col--55 {
    width: 55%; }
  .col--56 {
    width: 56%; }
  .col--57 {
    width: 57%; }
  .col--58 {
    width: 58%; }
  .col--59 {
    width: 59%; }
  .col--60 {
    width: 60%; }
  .col--61 {
    width: 61%; }
  .col--62 {
    width: 62%; }
  .col--63 {
    width: 63%; }
  .col--64 {
    width: 64%; }
  .col--65 {
    width: 65%; }
  .col--66 {
    width: 66%; }
  .col--67 {
    width: 67%; }
  .col--68 {
    width: 68%; }
  .col--69 {
    width: 69%; }
  .col--70 {
    width: 70%; }
  .col--71 {
    width: 71%; }
  .col--72 {
    width: 72%; }
  .col--73 {
    width: 73%; }
  .col--74 {
    width: 74%; }
  .col--75 {
    width: 75%; }
  .col--76 {
    width: 76%; }
  .col--77 {
    width: 77%; }
  .col--78 {
    width: 78%; }
  .col--79 {
    width: 79%; }
  .col--80 {
    width: 80%; }
  .col--81 {
    width: 81%; }
  .col--82 {
    width: 82%; }
  .col--83 {
    width: 83%; }
  .col--84 {
    width: 84%; }
  .col--85 {
    width: 85%; }
  .col--86 {
    width: 86%; }
  .col--87 {
    width: 87%; }
  .col--88 {
    width: 88%; }
  .col--89 {
    width: 89%; }
  .col--90 {
    width: 90%; }
  .col--91 {
    width: 91%; }
  .col--92 {
    width: 92%; }
  .col--93 {
    width: 93%; }
  .col--94 {
    width: 94%; }
  .col--95 {
    width: 95%; }
  .col--96 {
    width: 96%; }
  .col--97 {
    width: 97%; }
  .col--98 {
    width: 98%; }
  .col--99 {
    width: 99%; }
  .col--100 {
    width: 100%; }

@media screen and (max-width: 1199px) {
  .col--1199-1 {
    width: 1%; }
  .col--1199-2 {
    width: 2%; }
  .col--1199-3 {
    width: 3%; }
  .col--1199-4 {
    width: 4%; }
  .col--1199-5 {
    width: 5%; }
  .col--1199-6 {
    width: 6%; }
  .col--1199-7 {
    width: 7%; }
  .col--1199-8 {
    width: 8%; }
  .col--1199-9 {
    width: 9%; }
  .col--1199-10 {
    width: 10%; }
  .col--1199-11 {
    width: 11%; }
  .col--1199-12 {
    width: 12%; }
  .col--1199-13 {
    width: 13%; }
  .col--1199-14 {
    width: 14%; }
  .col--1199-15 {
    width: 15%; }
  .col--1199-16 {
    width: 16%; }
  .col--1199-17 {
    width: 17%; }
  .col--1199-18 {
    width: 18%; }
  .col--1199-19 {
    width: 19%; }
  .col--1199-20 {
    width: 20%; }
  .col--1199-21 {
    width: 21%; }
  .col--1199-22 {
    width: 22%; }
  .col--1199-23 {
    width: 23%; }
  .col--1199-24 {
    width: 24%; }
  .col--1199-25 {
    width: 25%; }
  .col--1199-26 {
    width: 26%; }
  .col--1199-27 {
    width: 27%; }
  .col--1199-28 {
    width: 28%; }
  .col--1199-29 {
    width: 29%; }
  .col--1199-30 {
    width: 30%; }
  .col--1199-31 {
    width: 31%; }
  .col--1199-32 {
    width: 32%; }
  .col--1199-33 {
    width: 33%; }
  .col--1199-34 {
    width: 34%; }
  .col--1199-35 {
    width: 35%; }
  .col--1199-36 {
    width: 36%; }
  .col--1199-37 {
    width: 37%; }
  .col--1199-38 {
    width: 38%; }
  .col--1199-39 {
    width: 39%; }
  .col--1199-40 {
    width: 40%; }
  .col--1199-41 {
    width: 41%; }
  .col--1199-42 {
    width: 42%; }
  .col--1199-43 {
    width: 43%; }
  .col--1199-44 {
    width: 44%; }
  .col--1199-45 {
    width: 45%; }
  .col--1199-46 {
    width: 46%; }
  .col--1199-47 {
    width: 47%; }
  .col--1199-48 {
    width: 48%; }
  .col--1199-49 {
    width: 49%; }
  .col--1199-50 {
    width: 50%; }
  .col--1199-51 {
    width: 51%; }
  .col--1199-52 {
    width: 52%; }
  .col--1199-53 {
    width: 53%; }
  .col--1199-54 {
    width: 54%; }
  .col--1199-55 {
    width: 55%; }
  .col--1199-56 {
    width: 56%; }
  .col--1199-57 {
    width: 57%; }
  .col--1199-58 {
    width: 58%; }
  .col--1199-59 {
    width: 59%; }
  .col--1199-60 {
    width: 60%; }
  .col--1199-61 {
    width: 61%; }
  .col--1199-62 {
    width: 62%; }
  .col--1199-63 {
    width: 63%; }
  .col--1199-64 {
    width: 64%; }
  .col--1199-65 {
    width: 65%; }
  .col--1199-66 {
    width: 66%; }
  .col--1199-67 {
    width: 67%; }
  .col--1199-68 {
    width: 68%; }
  .col--1199-69 {
    width: 69%; }
  .col--1199-70 {
    width: 70%; }
  .col--1199-71 {
    width: 71%; }
  .col--1199-72 {
    width: 72%; }
  .col--1199-73 {
    width: 73%; }
  .col--1199-74 {
    width: 74%; }
  .col--1199-75 {
    width: 75%; }
  .col--1199-76 {
    width: 76%; }
  .col--1199-77 {
    width: 77%; }
  .col--1199-78 {
    width: 78%; }
  .col--1199-79 {
    width: 79%; }
  .col--1199-80 {
    width: 80%; }
  .col--1199-81 {
    width: 81%; }
  .col--1199-82 {
    width: 82%; }
  .col--1199-83 {
    width: 83%; }
  .col--1199-84 {
    width: 84%; }
  .col--1199-85 {
    width: 85%; }
  .col--1199-86 {
    width: 86%; }
  .col--1199-87 {
    width: 87%; }
  .col--1199-88 {
    width: 88%; }
  .col--1199-89 {
    width: 89%; }
  .col--1199-90 {
    width: 90%; }
  .col--1199-91 {
    width: 91%; }
  .col--1199-92 {
    width: 92%; }
  .col--1199-93 {
    width: 93%; }
  .col--1199-94 {
    width: 94%; }
  .col--1199-95 {
    width: 95%; }
  .col--1199-96 {
    width: 96%; }
  .col--1199-97 {
    width: 97%; }
  .col--1199-98 {
    width: 98%; }
  .col--1199-99 {
    width: 99%; }
  .col--1199-100 {
    width: 100%; } }

@media screen and (max-width: 1023px) {
  .col--1023-1 {
    width: 1%; }
  .col--1023-2 {
    width: 2%; }
  .col--1023-3 {
    width: 3%; }
  .col--1023-4 {
    width: 4%; }
  .col--1023-5 {
    width: 5%; }
  .col--1023-6 {
    width: 6%; }
  .col--1023-7 {
    width: 7%; }
  .col--1023-8 {
    width: 8%; }
  .col--1023-9 {
    width: 9%; }
  .col--1023-10 {
    width: 10%; }
  .col--1023-11 {
    width: 11%; }
  .col--1023-12 {
    width: 12%; }
  .col--1023-13 {
    width: 13%; }
  .col--1023-14 {
    width: 14%; }
  .col--1023-15 {
    width: 15%; }
  .col--1023-16 {
    width: 16%; }
  .col--1023-17 {
    width: 17%; }
  .col--1023-18 {
    width: 18%; }
  .col--1023-19 {
    width: 19%; }
  .col--1023-20 {
    width: 20%; }
  .col--1023-21 {
    width: 21%; }
  .col--1023-22 {
    width: 22%; }
  .col--1023-23 {
    width: 23%; }
  .col--1023-24 {
    width: 24%; }
  .col--1023-25 {
    width: 25%; }
  .col--1023-26 {
    width: 26%; }
  .col--1023-27 {
    width: 27%; }
  .col--1023-28 {
    width: 28%; }
  .col--1023-29 {
    width: 29%; }
  .col--1023-30 {
    width: 30%; }
  .col--1023-31 {
    width: 31%; }
  .col--1023-32 {
    width: 32%; }
  .col--1023-33 {
    width: 33%; }
  .col--1023-34 {
    width: 34%; }
  .col--1023-35 {
    width: 35%; }
  .col--1023-36 {
    width: 36%; }
  .col--1023-37 {
    width: 37%; }
  .col--1023-38 {
    width: 38%; }
  .col--1023-39 {
    width: 39%; }
  .col--1023-40 {
    width: 40%; }
  .col--1023-41 {
    width: 41%; }
  .col--1023-42 {
    width: 42%; }
  .col--1023-43 {
    width: 43%; }
  .col--1023-44 {
    width: 44%; }
  .col--1023-45 {
    width: 45%; }
  .col--1023-46 {
    width: 46%; }
  .col--1023-47 {
    width: 47%; }
  .col--1023-48 {
    width: 48%; }
  .col--1023-49 {
    width: 49%; }
  .col--1023-50 {
    width: 50%; }
  .col--1023-51 {
    width: 51%; }
  .col--1023-52 {
    width: 52%; }
  .col--1023-53 {
    width: 53%; }
  .col--1023-54 {
    width: 54%; }
  .col--1023-55 {
    width: 55%; }
  .col--1023-56 {
    width: 56%; }
  .col--1023-57 {
    width: 57%; }
  .col--1023-58 {
    width: 58%; }
  .col--1023-59 {
    width: 59%; }
  .col--1023-60 {
    width: 60%; }
  .col--1023-61 {
    width: 61%; }
  .col--1023-62 {
    width: 62%; }
  .col--1023-63 {
    width: 63%; }
  .col--1023-64 {
    width: 64%; }
  .col--1023-65 {
    width: 65%; }
  .col--1023-66 {
    width: 66%; }
  .col--1023-67 {
    width: 67%; }
  .col--1023-68 {
    width: 68%; }
  .col--1023-69 {
    width: 69%; }
  .col--1023-70 {
    width: 70%; }
  .col--1023-71 {
    width: 71%; }
  .col--1023-72 {
    width: 72%; }
  .col--1023-73 {
    width: 73%; }
  .col--1023-74 {
    width: 74%; }
  .col--1023-75 {
    width: 75%; }
  .col--1023-76 {
    width: 76%; }
  .col--1023-77 {
    width: 77%; }
  .col--1023-78 {
    width: 78%; }
  .col--1023-79 {
    width: 79%; }
  .col--1023-80 {
    width: 80%; }
  .col--1023-81 {
    width: 81%; }
  .col--1023-82 {
    width: 82%; }
  .col--1023-83 {
    width: 83%; }
  .col--1023-84 {
    width: 84%; }
  .col--1023-85 {
    width: 85%; }
  .col--1023-86 {
    width: 86%; }
  .col--1023-87 {
    width: 87%; }
  .col--1023-88 {
    width: 88%; }
  .col--1023-89 {
    width: 89%; }
  .col--1023-90 {
    width: 90%; }
  .col--1023-91 {
    width: 91%; }
  .col--1023-92 {
    width: 92%; }
  .col--1023-93 {
    width: 93%; }
  .col--1023-94 {
    width: 94%; }
  .col--1023-95 {
    width: 95%; }
  .col--1023-96 {
    width: 96%; }
  .col--1023-97 {
    width: 97%; }
  .col--1023-98 {
    width: 98%; }
  .col--1023-99 {
    width: 99%; }
  .col--1023-100 {
    width: 100%; } }

@media screen and (max-width: 959px) {
  .col--959-1 {
    width: 1%; }
  .col--959-2 {
    width: 2%; }
  .col--959-3 {
    width: 3%; }
  .col--959-4 {
    width: 4%; }
  .col--959-5 {
    width: 5%; }
  .col--959-6 {
    width: 6%; }
  .col--959-7 {
    width: 7%; }
  .col--959-8 {
    width: 8%; }
  .col--959-9 {
    width: 9%; }
  .col--959-10 {
    width: 10%; }
  .col--959-11 {
    width: 11%; }
  .col--959-12 {
    width: 12%; }
  .col--959-13 {
    width: 13%; }
  .col--959-14 {
    width: 14%; }
  .col--959-15 {
    width: 15%; }
  .col--959-16 {
    width: 16%; }
  .col--959-17 {
    width: 17%; }
  .col--959-18 {
    width: 18%; }
  .col--959-19 {
    width: 19%; }
  .col--959-20 {
    width: 20%; }
  .col--959-21 {
    width: 21%; }
  .col--959-22 {
    width: 22%; }
  .col--959-23 {
    width: 23%; }
  .col--959-24 {
    width: 24%; }
  .col--959-25 {
    width: 25%; }
  .col--959-26 {
    width: 26%; }
  .col--959-27 {
    width: 27%; }
  .col--959-28 {
    width: 28%; }
  .col--959-29 {
    width: 29%; }
  .col--959-30 {
    width: 30%; }
  .col--959-31 {
    width: 31%; }
  .col--959-32 {
    width: 32%; }
  .col--959-33 {
    width: 33%; }
  .col--959-34 {
    width: 34%; }
  .col--959-35 {
    width: 35%; }
  .col--959-36 {
    width: 36%; }
  .col--959-37 {
    width: 37%; }
  .col--959-38 {
    width: 38%; }
  .col--959-39 {
    width: 39%; }
  .col--959-40 {
    width: 40%; }
  .col--959-41 {
    width: 41%; }
  .col--959-42 {
    width: 42%; }
  .col--959-43 {
    width: 43%; }
  .col--959-44 {
    width: 44%; }
  .col--959-45 {
    width: 45%; }
  .col--959-46 {
    width: 46%; }
  .col--959-47 {
    width: 47%; }
  .col--959-48 {
    width: 48%; }
  .col--959-49 {
    width: 49%; }
  .col--959-50 {
    width: 50%; }
  .col--959-51 {
    width: 51%; }
  .col--959-52 {
    width: 52%; }
  .col--959-53 {
    width: 53%; }
  .col--959-54 {
    width: 54%; }
  .col--959-55 {
    width: 55%; }
  .col--959-56 {
    width: 56%; }
  .col--959-57 {
    width: 57%; }
  .col--959-58 {
    width: 58%; }
  .col--959-59 {
    width: 59%; }
  .col--959-60 {
    width: 60%; }
  .col--959-61 {
    width: 61%; }
  .col--959-62 {
    width: 62%; }
  .col--959-63 {
    width: 63%; }
  .col--959-64 {
    width: 64%; }
  .col--959-65 {
    width: 65%; }
  .col--959-66 {
    width: 66%; }
  .col--959-67 {
    width: 67%; }
  .col--959-68 {
    width: 68%; }
  .col--959-69 {
    width: 69%; }
  .col--959-70 {
    width: 70%; }
  .col--959-71 {
    width: 71%; }
  .col--959-72 {
    width: 72%; }
  .col--959-73 {
    width: 73%; }
  .col--959-74 {
    width: 74%; }
  .col--959-75 {
    width: 75%; }
  .col--959-76 {
    width: 76%; }
  .col--959-77 {
    width: 77%; }
  .col--959-78 {
    width: 78%; }
  .col--959-79 {
    width: 79%; }
  .col--959-80 {
    width: 80%; }
  .col--959-81 {
    width: 81%; }
  .col--959-82 {
    width: 82%; }
  .col--959-83 {
    width: 83%; }
  .col--959-84 {
    width: 84%; }
  .col--959-85 {
    width: 85%; }
  .col--959-86 {
    width: 86%; }
  .col--959-87 {
    width: 87%; }
  .col--959-88 {
    width: 88%; }
  .col--959-89 {
    width: 89%; }
  .col--959-90 {
    width: 90%; }
  .col--959-91 {
    width: 91%; }
  .col--959-92 {
    width: 92%; }
  .col--959-93 {
    width: 93%; }
  .col--959-94 {
    width: 94%; }
  .col--959-95 {
    width: 95%; }
  .col--959-96 {
    width: 96%; }
  .col--959-97 {
    width: 97%; }
  .col--959-98 {
    width: 98%; }
  .col--959-99 {
    width: 99%; }
  .col--959-100 {
    width: 100%; } }

@media screen and (max-width: 767px) {
  .col--767-1 {
    width: 1%; }
  .col--767-2 {
    width: 2%; }
  .col--767-3 {
    width: 3%; }
  .col--767-4 {
    width: 4%; }
  .col--767-5 {
    width: 5%; }
  .col--767-6 {
    width: 6%; }
  .col--767-7 {
    width: 7%; }
  .col--767-8 {
    width: 8%; }
  .col--767-9 {
    width: 9%; }
  .col--767-10 {
    width: 10%; }
  .col--767-11 {
    width: 11%; }
  .col--767-12 {
    width: 12%; }
  .col--767-13 {
    width: 13%; }
  .col--767-14 {
    width: 14%; }
  .col--767-15 {
    width: 15%; }
  .col--767-16 {
    width: 16%; }
  .col--767-17 {
    width: 17%; }
  .col--767-18 {
    width: 18%; }
  .col--767-19 {
    width: 19%; }
  .col--767-20 {
    width: 20%; }
  .col--767-21 {
    width: 21%; }
  .col--767-22 {
    width: 22%; }
  .col--767-23 {
    width: 23%; }
  .col--767-24 {
    width: 24%; }
  .col--767-25 {
    width: 25%; }
  .col--767-26 {
    width: 26%; }
  .col--767-27 {
    width: 27%; }
  .col--767-28 {
    width: 28%; }
  .col--767-29 {
    width: 29%; }
  .col--767-30 {
    width: 30%; }
  .col--767-31 {
    width: 31%; }
  .col--767-32 {
    width: 32%; }
  .col--767-33 {
    width: 33%; }
  .col--767-34 {
    width: 34%; }
  .col--767-35 {
    width: 35%; }
  .col--767-36 {
    width: 36%; }
  .col--767-37 {
    width: 37%; }
  .col--767-38 {
    width: 38%; }
  .col--767-39 {
    width: 39%; }
  .col--767-40 {
    width: 40%; }
  .col--767-41 {
    width: 41%; }
  .col--767-42 {
    width: 42%; }
  .col--767-43 {
    width: 43%; }
  .col--767-44 {
    width: 44%; }
  .col--767-45 {
    width: 45%; }
  .col--767-46 {
    width: 46%; }
  .col--767-47 {
    width: 47%; }
  .col--767-48 {
    width: 48%; }
  .col--767-49 {
    width: 49%; }
  .col--767-50 {
    width: 50%; }
  .col--767-51 {
    width: 51%; }
  .col--767-52 {
    width: 52%; }
  .col--767-53 {
    width: 53%; }
  .col--767-54 {
    width: 54%; }
  .col--767-55 {
    width: 55%; }
  .col--767-56 {
    width: 56%; }
  .col--767-57 {
    width: 57%; }
  .col--767-58 {
    width: 58%; }
  .col--767-59 {
    width: 59%; }
  .col--767-60 {
    width: 60%; }
  .col--767-61 {
    width: 61%; }
  .col--767-62 {
    width: 62%; }
  .col--767-63 {
    width: 63%; }
  .col--767-64 {
    width: 64%; }
  .col--767-65 {
    width: 65%; }
  .col--767-66 {
    width: 66%; }
  .col--767-67 {
    width: 67%; }
  .col--767-68 {
    width: 68%; }
  .col--767-69 {
    width: 69%; }
  .col--767-70 {
    width: 70%; }
  .col--767-71 {
    width: 71%; }
  .col--767-72 {
    width: 72%; }
  .col--767-73 {
    width: 73%; }
  .col--767-74 {
    width: 74%; }
  .col--767-75 {
    width: 75%; }
  .col--767-76 {
    width: 76%; }
  .col--767-77 {
    width: 77%; }
  .col--767-78 {
    width: 78%; }
  .col--767-79 {
    width: 79%; }
  .col--767-80 {
    width: 80%; }
  .col--767-81 {
    width: 81%; }
  .col--767-82 {
    width: 82%; }
  .col--767-83 {
    width: 83%; }
  .col--767-84 {
    width: 84%; }
  .col--767-85 {
    width: 85%; }
  .col--767-86 {
    width: 86%; }
  .col--767-87 {
    width: 87%; }
  .col--767-88 {
    width: 88%; }
  .col--767-89 {
    width: 89%; }
  .col--767-90 {
    width: 90%; }
  .col--767-91 {
    width: 91%; }
  .col--767-92 {
    width: 92%; }
  .col--767-93 {
    width: 93%; }
  .col--767-94 {
    width: 94%; }
  .col--767-95 {
    width: 95%; }
  .col--767-96 {
    width: 96%; }
  .col--767-97 {
    width: 97%; }
  .col--767-98 {
    width: 98%; }
  .col--767-99 {
    width: 99%; }
  .col--767-100 {
    width: 100%; } }

@media screen and (max-width: 639px) {
  .col--639-1 {
    width: 1%; }
  .col--639-2 {
    width: 2%; }
  .col--639-3 {
    width: 3%; }
  .col--639-4 {
    width: 4%; }
  .col--639-5 {
    width: 5%; }
  .col--639-6 {
    width: 6%; }
  .col--639-7 {
    width: 7%; }
  .col--639-8 {
    width: 8%; }
  .col--639-9 {
    width: 9%; }
  .col--639-10 {
    width: 10%; }
  .col--639-11 {
    width: 11%; }
  .col--639-12 {
    width: 12%; }
  .col--639-13 {
    width: 13%; }
  .col--639-14 {
    width: 14%; }
  .col--639-15 {
    width: 15%; }
  .col--639-16 {
    width: 16%; }
  .col--639-17 {
    width: 17%; }
  .col--639-18 {
    width: 18%; }
  .col--639-19 {
    width: 19%; }
  .col--639-20 {
    width: 20%; }
  .col--639-21 {
    width: 21%; }
  .col--639-22 {
    width: 22%; }
  .col--639-23 {
    width: 23%; }
  .col--639-24 {
    width: 24%; }
  .col--639-25 {
    width: 25%; }
  .col--639-26 {
    width: 26%; }
  .col--639-27 {
    width: 27%; }
  .col--639-28 {
    width: 28%; }
  .col--639-29 {
    width: 29%; }
  .col--639-30 {
    width: 30%; }
  .col--639-31 {
    width: 31%; }
  .col--639-32 {
    width: 32%; }
  .col--639-33 {
    width: 33%; }
  .col--639-34 {
    width: 34%; }
  .col--639-35 {
    width: 35%; }
  .col--639-36 {
    width: 36%; }
  .col--639-37 {
    width: 37%; }
  .col--639-38 {
    width: 38%; }
  .col--639-39 {
    width: 39%; }
  .col--639-40 {
    width: 40%; }
  .col--639-41 {
    width: 41%; }
  .col--639-42 {
    width: 42%; }
  .col--639-43 {
    width: 43%; }
  .col--639-44 {
    width: 44%; }
  .col--639-45 {
    width: 45%; }
  .col--639-46 {
    width: 46%; }
  .col--639-47 {
    width: 47%; }
  .col--639-48 {
    width: 48%; }
  .col--639-49 {
    width: 49%; }
  .col--639-50 {
    width: 50%; }
  .col--639-51 {
    width: 51%; }
  .col--639-52 {
    width: 52%; }
  .col--639-53 {
    width: 53%; }
  .col--639-54 {
    width: 54%; }
  .col--639-55 {
    width: 55%; }
  .col--639-56 {
    width: 56%; }
  .col--639-57 {
    width: 57%; }
  .col--639-58 {
    width: 58%; }
  .col--639-59 {
    width: 59%; }
  .col--639-60 {
    width: 60%; }
  .col--639-61 {
    width: 61%; }
  .col--639-62 {
    width: 62%; }
  .col--639-63 {
    width: 63%; }
  .col--639-64 {
    width: 64%; }
  .col--639-65 {
    width: 65%; }
  .col--639-66 {
    width: 66%; }
  .col--639-67 {
    width: 67%; }
  .col--639-68 {
    width: 68%; }
  .col--639-69 {
    width: 69%; }
  .col--639-70 {
    width: 70%; }
  .col--639-71 {
    width: 71%; }
  .col--639-72 {
    width: 72%; }
  .col--639-73 {
    width: 73%; }
  .col--639-74 {
    width: 74%; }
  .col--639-75 {
    width: 75%; }
  .col--639-76 {
    width: 76%; }
  .col--639-77 {
    width: 77%; }
  .col--639-78 {
    width: 78%; }
  .col--639-79 {
    width: 79%; }
  .col--639-80 {
    width: 80%; }
  .col--639-81 {
    width: 81%; }
  .col--639-82 {
    width: 82%; }
  .col--639-83 {
    width: 83%; }
  .col--639-84 {
    width: 84%; }
  .col--639-85 {
    width: 85%; }
  .col--639-86 {
    width: 86%; }
  .col--639-87 {
    width: 87%; }
  .col--639-88 {
    width: 88%; }
  .col--639-89 {
    width: 89%; }
  .col--639-90 {
    width: 90%; }
  .col--639-91 {
    width: 91%; }
  .col--639-92 {
    width: 92%; }
  .col--639-93 {
    width: 93%; }
  .col--639-94 {
    width: 94%; }
  .col--639-95 {
    width: 95%; }
  .col--639-96 {
    width: 96%; }
  .col--639-97 {
    width: 97%; }
  .col--639-98 {
    width: 98%; }
  .col--639-99 {
    width: 99%; }
  .col--639-100 {
    width: 100%; } }

@media screen and (max-width: 479px) {
  .col--479-1 {
    width: 1%; }
  .col--479-2 {
    width: 2%; }
  .col--479-3 {
    width: 3%; }
  .col--479-4 {
    width: 4%; }
  .col--479-5 {
    width: 5%; }
  .col--479-6 {
    width: 6%; }
  .col--479-7 {
    width: 7%; }
  .col--479-8 {
    width: 8%; }
  .col--479-9 {
    width: 9%; }
  .col--479-10 {
    width: 10%; }
  .col--479-11 {
    width: 11%; }
  .col--479-12 {
    width: 12%; }
  .col--479-13 {
    width: 13%; }
  .col--479-14 {
    width: 14%; }
  .col--479-15 {
    width: 15%; }
  .col--479-16 {
    width: 16%; }
  .col--479-17 {
    width: 17%; }
  .col--479-18 {
    width: 18%; }
  .col--479-19 {
    width: 19%; }
  .col--479-20 {
    width: 20%; }
  .col--479-21 {
    width: 21%; }
  .col--479-22 {
    width: 22%; }
  .col--479-23 {
    width: 23%; }
  .col--479-24 {
    width: 24%; }
  .col--479-25 {
    width: 25%; }
  .col--479-26 {
    width: 26%; }
  .col--479-27 {
    width: 27%; }
  .col--479-28 {
    width: 28%; }
  .col--479-29 {
    width: 29%; }
  .col--479-30 {
    width: 30%; }
  .col--479-31 {
    width: 31%; }
  .col--479-32 {
    width: 32%; }
  .col--479-33 {
    width: 33%; }
  .col--479-34 {
    width: 34%; }
  .col--479-35 {
    width: 35%; }
  .col--479-36 {
    width: 36%; }
  .col--479-37 {
    width: 37%; }
  .col--479-38 {
    width: 38%; }
  .col--479-39 {
    width: 39%; }
  .col--479-40 {
    width: 40%; }
  .col--479-41 {
    width: 41%; }
  .col--479-42 {
    width: 42%; }
  .col--479-43 {
    width: 43%; }
  .col--479-44 {
    width: 44%; }
  .col--479-45 {
    width: 45%; }
  .col--479-46 {
    width: 46%; }
  .col--479-47 {
    width: 47%; }
  .col--479-48 {
    width: 48%; }
  .col--479-49 {
    width: 49%; }
  .col--479-50 {
    width: 50%; }
  .col--479-51 {
    width: 51%; }
  .col--479-52 {
    width: 52%; }
  .col--479-53 {
    width: 53%; }
  .col--479-54 {
    width: 54%; }
  .col--479-55 {
    width: 55%; }
  .col--479-56 {
    width: 56%; }
  .col--479-57 {
    width: 57%; }
  .col--479-58 {
    width: 58%; }
  .col--479-59 {
    width: 59%; }
  .col--479-60 {
    width: 60%; }
  .col--479-61 {
    width: 61%; }
  .col--479-62 {
    width: 62%; }
  .col--479-63 {
    width: 63%; }
  .col--479-64 {
    width: 64%; }
  .col--479-65 {
    width: 65%; }
  .col--479-66 {
    width: 66%; }
  .col--479-67 {
    width: 67%; }
  .col--479-68 {
    width: 68%; }
  .col--479-69 {
    width: 69%; }
  .col--479-70 {
    width: 70%; }
  .col--479-71 {
    width: 71%; }
  .col--479-72 {
    width: 72%; }
  .col--479-73 {
    width: 73%; }
  .col--479-74 {
    width: 74%; }
  .col--479-75 {
    width: 75%; }
  .col--479-76 {
    width: 76%; }
  .col--479-77 {
    width: 77%; }
  .col--479-78 {
    width: 78%; }
  .col--479-79 {
    width: 79%; }
  .col--479-80 {
    width: 80%; }
  .col--479-81 {
    width: 81%; }
  .col--479-82 {
    width: 82%; }
  .col--479-83 {
    width: 83%; }
  .col--479-84 {
    width: 84%; }
  .col--479-85 {
    width: 85%; }
  .col--479-86 {
    width: 86%; }
  .col--479-87 {
    width: 87%; }
  .col--479-88 {
    width: 88%; }
  .col--479-89 {
    width: 89%; }
  .col--479-90 {
    width: 90%; }
  .col--479-91 {
    width: 91%; }
  .col--479-92 {
    width: 92%; }
  .col--479-93 {
    width: 93%; }
  .col--479-94 {
    width: 94%; }
  .col--479-95 {
    width: 95%; }
  .col--479-96 {
    width: 96%; }
  .col--479-97 {
    width: 97%; }
  .col--479-98 {
    width: 98%; }
  .col--479-99 {
    width: 99%; }
  .col--479-100 {
    width: 100%; } }

/*
 * Basic reset
 */
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 15px;
  line-height: 1.5; }

body {
  margin: 0; }

body > svg {
  display: none; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

address {
  font-style: normal;
  padding: 0;
  margin: 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  display: block;
  border: 0;
  max-width: 100%;
  height: auto; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

/*
 * Helper classes
 */
html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  line-height: 28px;
  color: #5a5a5a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  width: 100%;
  height: 100%; }
  body.nav-mobile-is-active {
    overflow: hidden; }

h1 {
  font-family: "Playfair Display", serif;
  font-size: 2.4rem;
  font-weight: normal;
  line-height: 1.2em;
  color: #191516;
  margin: 1.4em 0 0; }

h2 {
  font-family: "Playfair Display", serif;
  font-size: 2rem;
  font-weight: normal;
  line-height: 1.2em;
  color: #191516;
  margin: 1.4em 0 0; }

h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.2em;
  color: #191516;
  margin: 1.4em 0 0; }

p {
  margin: 1.5em 0 0; }

a {
  outline: none;
  text-decoration: none;
  color: inherit; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

.page {
  overflow: auto;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%; }

.container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px; }

.wysi,
.cke_editable {
  width: 100%; }
  .wysi > *,
  .cke_editable > * {
    margin-top: 1.4em; }
  .wysi > *:first-child,
  .cke_editable > *:first-child {
    margin-top: 0; }
  .wysi strong,
  .cke_editable strong {
    color: #191516; }
  .wysi a,
  .cke_editable a {
    text-decoration: underline; }
    .wysi a:hover,
    .cke_editable a:hover {
      text-decoration: none; }
  .wysi .tbl-mobile,
  .cke_editable .tbl-mobile {
    display: none; }
  .wysi .tbl-horizontal tbody th,
  .cke_editable .tbl-horizontal tbody th {
    background-color: #191516;
    border-top-color: white;
    color: white; }
  .wysi table,
  .cke_editable table {
    width: 100%;
    text-align: left;
    border: 2px solid white;
    background-color: white; }
  .wysi thead th,
  .cke_editable thead th {
    background-color: #191516;
    color: white; }
  .wysi tbody th,
  .cke_editable tbody th {
    background-color: #f7f7f7; }
  .wysi tbody tr td,
  .wysi tbody tr th,
  .cke_editable tbody tr td,
  .cke_editable tbody tr th {
    border-top: 1px solid #ebebeb; }
  .wysi tbody tr:first-child td,
  .wysi tbody tr:first-child th,
  .cke_editable tbody tr:first-child td,
  .cke_editable tbody tr:first-child th {
    border-top: none; }
  .wysi td,
  .wysi th,
  .cke_editable td,
  .cke_editable th {
    padding: 8px 12px;
    border: none; }
  .wysi th,
  .cke_editable th {
    font-weight: bold; }

.edit {
  position: fixed;
  bottom: 20px;
  right: 20px; }
  .edit a {
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    background-color: #0F9D58;
    border-radius: 50%;
    overflow: hidden;
    text-indent: -999em;
    box-shadow: 0 2px 0 #0b6e3e; }
    .edit a:hover {
      background-color: #0e9453; }
    .edit a:active {
      top: 2px;
      box-shadow: 0 0 0 transparent; }

.edit__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: white;
  width: 20px;
  height: 20px; }

@media screen and (max-width: 1023px) {
  h1 {
    font-size: 2rem;
    line-height: 1.2em; }
  h2 {
    font-size: 1.6rem;
    line-height: 1.2em; }
  h3 {
    font-size: 1.2rem;
    line-height: 1.2em; }
  .wysi .tbl-mobile {
    display: table; }
  .wysi .tbl-horizontal.tbl-mobile th,
  .wysi .tbl-vertical.tbl-mobile th {
    border-top: none; }
    .wysi .tbl-horizontal.tbl-mobile th + *,
    .wysi .tbl-vertical.tbl-mobile th + * {
      border-top: none; }
  .wysi .tbl-horizontal.tbl-mobile .tbl-head,
  .wysi .tbl-vertical.tbl-mobile .tbl-head {
    background-color: #191516;
    color: white; }
  .wysi .tbl-multidimensional.tbl-mobile .tbl-head {
    background-color: #fff;
    color: white; }
  .wysi .tbl-multidimensional.tbl-mobile .tbl-key,
  .wysi .tbl-multidimensional.tbl-mobile .tbl-value {
    width: 50%; }
  .wysi table {
    display: none; } }

.section--blocks {
  width: 100%;
  overflow: hidden; }
  .section--blocks .container {
    display: flex;
    flex-direction: column; }
  .section--blocks .block--image-left {
    display: flex;
    margin: 0 calc(50% - 50vw);
    background-color: #191516; }
    .section--blocks .block--image-left .block__image {
      width: 50%;
      min-height: 600px;
      order: 0;
      background-position: center center;
      background-size: cover; }
    .section--blocks .block--image-left .block__text {
      display: flex;
      align-items: center;
      width: 50%;
      order: 1; }
    .section--blocks .block--image-left .wysi {
      padding: 120px;
      color: white; }
      .section--blocks .block--image-left .wysi h1,
      .section--blocks .block--image-left .wysi h2,
      .section--blocks .block--image-left .wysi h3 {
        color: white; }
      .section--blocks .block--image-left .wysi strong {
        color: white; }
  .section--blocks .block--image-right {
    display: flex;
    margin: 0 calc(50% - 50vw);
    background-color: #191516; }
    .section--blocks .block--image-right .block__image {
      width: 50%;
      min-height: 600px;
      order: 2;
      background-position: center center;
      background-size: cover; }
    .section--blocks .block--image-right .block__text {
      display: flex;
      align-items: center;
      width: 50%;
      order: 1; }
    .section--blocks .block--image-right .wysi {
      padding: 120px;
      color: white; }
      .section--blocks .block--image-right .wysi h1,
      .section--blocks .block--image-right .wysi h2,
      .section--blocks .block--image-right .wysi h3 {
        color: white; }
      .section--blocks .block--image-right .wysi strong {
        color: white; }

@media screen and (max-width: 1199px) {
  .section--blocks .block--image-right .wysi,
  .section--blocks .block--image-left .wysi {
    padding: 60px; } }

@media screen and (max-width: 1023px) {
  .section--blocks .block--image-right {
    flex-wrap: wrap; }
    .section--blocks .block--image-right .block__image {
      width: 100%; }
    .section--blocks .block--image-right .block__text {
      width: 100%; }
    .section--blocks .block--image-right .wysi {
      width: 100%; }
  .section--blocks .block--image-left {
    flex-wrap: wrap; }
    .section--blocks .block--image-left .block__image {
      width: 100%;
      order: 2; }
    .section--blocks .block--image-left .block__text {
      width: 100%;
      order: 1; }
    .section--blocks .block--image-left .wysi {
      width: 100%;
      padding: 60px; } }

@media screen and (max-width: 767px) {
  .section--blocks .block--image-left .block__image,
  .section--blocks .block--image-right .block__image {
    min-height: 480px; } }

.btn {
  display: inline-flex !important;
  padding: 17px 36px !important;
  font-size: 0.86667rem !important;
  font-weight: 900 !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  text-align: center !important;
  line-height: 1.13333rem !important;
  letter-spacing: 0.2em !important;
  color: white !important;
  border-radius: 999em !important;
  transition: all 0.2s ease !important; }
  .btn--color-1 {
    background-color: #191516; }
    .btn--color-1:hover {
      background-color: black; }
  .btn--color-2 {
    background-color: #ccc; }
    .btn--color-2:hover {
      background-color: #b3b3b3; }
  .btn--color-3 {
    background-color: #fff; }
    .btn--color-3:hover {
      background-color: #e6e6e6; }

.nav--pagination {
  width: 100%; }

.nav__list {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0; }

.nav__item {
  margin: 0 3px; }

.nav__item:not(.nav__item--next):not(.nav__item--prev) .nav__link {
  display: block;
  width: 44px;
  height: 44px;
  border-radius: 22px;
  background-color: #fff;
  font-weight: bold;
  text-align: center;
  line-height: 44px;
  color: white;
  transition: all 0.2s ease; }
  .nav__item:not(.nav__item--next):not(.nav__item--prev) .nav__link:hover, .nav__item:not(.nav__item--next):not(.nav__item--prev) .nav__link.active {
    background-color: #191516; }

.nav__item--next .nav__link,
.nav__item--prev .nav__link {
  height: 44px;
  padding: 0 20px;
  font-weight: bold;
  line-height: 44px;
  transition: all 0.2s ease; }
  .nav__item--next .nav__link:hover,
  .nav__item--prev .nav__link:hover {
    color: #272727; }

input,
textarea {
  width: 100%;
  height: auto;
  min-height: 44px;
  border: none;
  border-bottom: 2px solid #e6e6e6;
  outline: none;
  padding: 8px 10px;
  font-size: 1.06667rem;
  line-height: 1.6rem;
  transition: all 0.2s ease;
  background-color: transparent; }
  input:focus,
  textarea:focus {
    border-color: #191516; }
  .error input, .error
  textarea {
    border-color: #df5555; }
  .success input, .success
  textarea {
    border-color: #429e62; }

textarea {
  max-width: 100%;
  min-height: 150px;
  border-bottom: none;
  background-color: #f2f2f2; }

select {
  background: transparent;
  background-image: none;
  appearance: none;
  box-shadow: none;
  width: 100%;
  height: auto;
  min-height: 44px;
  border: 2px solid #cccccc;
  outline: none;
  padding: 8px 10px;
  font-size: 1.06667rem;
  line-height: 1.6rem;
  border-radius: 3px;
  transition: all 0.2s ease;
  background-color: transparent; }
  select::-ms-expand {
    display: none; }
  select:focus {
    border-color: #191516; }
  .error select {
    border-color: #df5555; }
  .success select {
    border-color: #429e62; }

button {
  border: 0;
  outline: 0; }

.form__label, input::placeholder {
  display: block;
  font-size: 0.93333rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: #191516;
  margin-bottom: 8px;
  margin-left: 10px; }

input::placeholder {
  transition: all 0.2s ease; }

input:focus::placeholder {
  color: #ccc; }

.form__options--inline {
  display: flex;
  flex-wrap: wrap;
  margin: -3px -12px; }
  .form__options--inline > * {
    padding: 3px 12px; }

.form__option input {
  display: none; }

.form__option label {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center; }

.form__option input:checked + label .form__input--checkbox:before, .form__option input:checked + label .form__input--checkbox:after {
  opacity: 1; }

.form__option input:checked + label .form__input--radiobutton:before {
  opacity: 1; }

.form__input--checkbox {
  position: relative;
  display: inline-flex;
  margin: 0 8px 0 0;
  width: 18px;
  height: 18px;
  border: 2px solid #cccccc; }
  .form__input--checkbox:before, .form__input--checkbox:after {
    content: '';
    display: block;
    width: 10px;
    height: 2px;
    background-color: #ccc;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0; }
  .form__input--checkbox:before {
    transform: translate(-50%, -50%) rotate(45deg); }
  .form__input--checkbox:after {
    transform: translate(-50%, -50%) rotate(-45deg); }

.form__input--radiobutton {
  position: relative;
  display: inline-flex;
  margin: 0 8px 0 0;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border: 2px solid #cccccc; }
  .form__input--radiobutton:before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #ccc;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%); }

.form__input--select {
  position: relative;
  width: 100%; }

.form__input-icon--select {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px; }
  .form__input-icon--select:before, .form__input-icon--select:after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    content: '';
    width: 0;
    height: 0; }
  .form__input-icon--select:before {
    top: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid gray; }
  .form__input-icon--select:after {
    bottom: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid gray; }

.form__input--date {
  position: relative;
  z-index: 0; }
  .form__input--date input {
    padding-right: 44px; }

.form__input-icon--date {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .form__input-icon--date .icon--calendar {
    width: 16px;
    height: 16px;
    fill: gray; }

#row-url {
  display: none; }

.form__fieldset {
  margin: -20px -15px;
  display: flex;
  flex-wrap: wrap; }

.form__row {
  padding: 20px 15px;
  width: 100%; }
  .form__row:first-child {
    margin-top: 0; }
  .form__row--text {
    width: 50%; }

.feedback {
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0s 0.2s, opacity 0.2s ease; }
  .feedback.error, .feedback.success {
    height: auto;
    overflow: auto;
    opacity: 1;
    transition: opacity 0.2s ease; }

.feedback--small {
  border-radius: 3px;
  font-size: 1.06667rem;
  font-weight: 700; }
  .feedback--small.error {
    color: #df5555; }
  .feedback--small.success {
    color: #429e62; }
  .feedback--small.error, .feedback--small.success {
    padding: 20px 15px; }

@media screen and (max-width: 767px) {
  .form__row--text {
    width: 100%; } }

.header {
  width: 100%; }

.header__logo {
  display: block;
  margin: 50px 0 0;
  width: 200px;
  height: 164px; }

.header--primary {
  position: relative;
  z-index: 1; }
  .header--primary .container {
    display: flex;
    justify-content: center;
    align-items: center; }

.header__btn--email {
  position: absolute;
  top: 100px;
  right: 30px;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #191516; }
  .header__btn--email .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    fill: white; }

.nav--mobile {
  display: none; }

.nav--primary {
  flex: 1 0 auto; }
  .nav--primary ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between; }
  .nav--primary li {
    display: inline-flex; }
  .nav--primary a {
    display: flex;
    font-size: 0.86667rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    transition: all 0.1s ease; }
    .nav--primary a:hover {
      color: #191516; }
  .nav--primary .is-active a {
    font-weight: 900;
    color: #191516; }

.nav--languages {
  position: relative; }
  .nav--languages ul {
    opacity: 0;
    padding: 0;
    margin: 0;
    flex-direction: column; }
    .nav-languages-is-active .nav--languages ul {
      opacity: 1;
      transition: opacity 0.2s ease; }
  .nav--languages li {
    display: inline-flex; }
  .nav--languages a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 0.86667rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: white;
    transition: all 0.1s ease; }
    .nav--languages a:not(.nav__toggle--languages) {
      background-color: #fff; }
      .nav--languages a:not(.nav__toggle--languages):hover {
        background-color: white; }

.nav__toggle--languages {
  background-color: #ccc; }
  .nav__toggle--languages:hover {
    background-color: #d9d9d9; }

.nav__toggle--languages + ul {
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0; }

.header--alert {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 70px;
  padding: 20px 30px;
  background-color: #191516; }
  .header--alert h1,
  .header--alert h2,
  .header--alert h3 {
    color: white; }
  .header--alert .wysi {
    color: white; }

.alert__btn--close {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  text-indent: -999em; }
  .alert__btn--close:before, .alert__btn--close:after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    display: block;
    width: 20px;
    height: 2px;
    background-color: white; }
  .alert__btn--close:before {
    transform: rotate(45deg); }
  .alert__btn--close:after {
    transform: rotate(-45deg); }

@media screen and (max-width: 1023px) {
  .header__logo {
    width: 150px;
    height: 123px; } }

.footer {
  overflow: hidden;
  padding: 80px 0;
  background-color: #191516; }
  .footer hr {
    display: flex;
    border-top: 1px solid #352c2e;
    width: 100%;
    height: 0; }
  .footer .grid {
    align-items: flex-start;
    margin: -20px; }
  .footer .col {
    padding: 20px; }
    .footer .col:last-child {
      display: flex;
      justify-content: space-between; }
  .footer .wysi {
    color: white; }
    .footer .wysi h1,
    .footer .wysi h2,
    .footer .wysi h3 {
      color: white; }

.footer__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  background-color: #191516;
  display: block;
  width: 220px;
  height: 198px; }

.footer__copyrights {
  display: inline-flex;
  font-size: 0.73333rem;
  color: gray; }

.footer__credits {
  display: inline-flex;
  font-size: 0.73333rem;
  color: gray; }
  .footer__credits a {
    transition: all 0.1s ease; }
    .footer__credits a:hover {
      color: white; }

.nav--social {
  width: 100%; }
  .nav--social ul {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 0; }
  .nav--social li {
    display: inline-flex;
    margin: 0 2px; }
    .nav--social li:first-child {
      margin-left: 0; }
    .nav--social li:last-child {
      margin-right: 0; }
  .nav--social a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    transition: all 0.1s ease; }
    .nav--social a:hover {
      background-color: #191516; }
  .nav--social .icon {
    width: 18px;
    height: 18px;
    fill: white; }

.nav--footer ul {
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column; }

.nav--footer li {
  display: inline-flex; }

.nav--footer a {
  color: white;
  transition: all 0.1s ease; }
  .nav--footer a:hover {
    color: #191516; }

.nav--footer .is-active a {
  font-weight: bold;
  color: #191516; }

.form--newsletter {
  margin-top: 1.5em; }
  .form--newsletter ::placeholder {
    font-size: 0.93333rem;
    font-weight: bold;
    color: white; }
  .form--newsletter input,
  .form--newsletter textarea {
    border-color: #f2f2f2;
    color: white; }
    .form--newsletter input:focus,
    .form--newsletter textarea:focus {
      border-color: #191516; }
  .form--newsletter .error input {
    border-color: #e78080; }
  .form--newsletter .success input {
    border-color: #59ba7b; }

@media screen and (max-width: 1023px) {
  .footer {
    padding: 40px 0; }
    .footer hr {
      margin: 0; }
  .footer__logo {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    margin: 0 auto;
    padding: 0;
    background-color: transparent;
    width: 150px;
    height: 123px;
    margin-bottom: 50px; }
  .nav--social ul {
    justify-content: center; }
  .footer .col:last-child {
    flex-direction: column;
    align-items: center; } }

.slideshow {
  position: relative;
  z-index: -1;
  margin: -264px 0 0;
  width: 100%;
  height: 100%;
  min-height: 768px;
  overflow: hidden;
  background-color: #191516; }
  .slideshow ul {
    display: flex;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%; }
  .slideshow li {
    width: 100%;
    height: 100%;
    display: flex; }
  .slideshow .flex-viewport {
    display: flex;
    width: 100%;
    height: 100%; }

.slideshow__slide {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center; }

.slide__content {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 120px 0;
  text-align: left;
  color: white; }
  .slide__content .wysi {
    padding: 30px 70px;
    border-left: 2px solid white; }
  .slide__content h1 {
    font-size: 4.8rem; }
  .slide__content h2 {
    font-size: 4rem; }
  .slide__content h3 {
    font-size: 1.6rem; }
  .slide__content h1,
  .slide__content h2,
  .slide__content h3 {
    color: white; }

.slide__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover; }

.animated .slide__image {
  transform: scale(1.025);
  transition: transform 15s ease; }

.slideshow__control {
  z-index: 99;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 76px;
  overflow: hidden;
  text-indent: -999em; }

.slideshow__control-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 40px;
  height: 76px;
  fill: white; }

.slideshow__control--next {
  right: 30px; }

.slideshow__control--prev {
  left: 30px; }

.section--seo {
  padding: 120px 0;
  background-color: white; }
  .section--seo .grid {
    margin: -40px; }
  .section--seo .col {
    padding: 40px; }
    .section--seo .col:last-child {
      align-self: flex-start; }

.section--gallery {
  background-color: #f7f7f7;
  padding: 120px 0; }
  .section--gallery .grid {
    align-items: flex-start; }
  .section--gallery .hidden {
    display: none; }

.gallery__image {
  display: block;
  overflow: hidden;
  position: relative; }
  .gallery__image img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .gallery__image--vertical {
    width: 100%;
    height: 0;
    padding-bottom: 150%; }
  .gallery__image--horizontal {
    width: 100%;
    height: 0;
    padding-bottom: calc(75% - 10px); }
    .gallery__image--horizontal img {
      max-width: none;
      max-height: 100%; }
  .gallery__image:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    transition: all 0.2s ease; }
  .gallery__image:after {
    content: attr(data-label);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
    border: 1px solid white;
    font-size: 0.86667rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    color: white;
    opacity: 0;
    transition: all 0.2s ease; }
  .gallery__image:hover:before {
    opacity: 0.15; }
  .gallery__image:hover:after {
    opacity: 1; }

.section--contact {
  padding: 120px 0;
  background-color: white; }
  .section--contact h2 {
    font-size: 3.2rem; }
  .section--contact .form {
    margin-top: 40px; }
  .section--contact .grid {
    align-items: flex-start;
    margin: -40px; }
  .section--contact .col {
    padding: 40px; }

@media screen and (max-width: 1023px) {
  .slide__content {
    padding: 60px 0; }
    .slide__content h1 {
      font-size: 4rem; }
    .slide__content h2 {
      font-size: 3.2rem; }
    .slide__content h3 {
      font-size: 1.2rem; }
  .section--seo {
    padding: 60px 0; }
    .section--seo .grid {
      margin: -20px; }
    .section--seo .col {
      padding: 20px; }
  .section--gallery {
    padding: 60px 0; }
  .section--contact {
    padding: 60px 0; }
    .section--contact h2 {
      font-size: 2.4rem; }
    .section--contact .grid {
      margin: -20px; }
    .section--contact .col {
      padding: 20px; }
      .section--contact .col:nth-child(1) {
        order: 2; }
      .section--contact .col:nth-child(2) {
        order: 1; } }

@media screen and (max-width: 767px) {
  .slide__content .wysi {
    padding: 0;
    border: 0; }
  .section--gallery > .grid-container > .grid > .col:nth-child(1) {
    order: 1; }
  .section--gallery > .grid-container > .grid > .col:nth-child(2) {
    order: 3; }
  .section--gallery > .grid-container > .grid > .col:nth-child(3) {
    order: 2; } }

@media screen and (max-width: 639px) {
  .section--gallery > .grid-container > .grid > .col:nth-child(1) {
    order: 1; }
  .section--gallery > .grid-container > .grid > .col:nth-child(2) {
    order: 2; }
  .section--gallery > .grid-container > .grid > .col:nth-child(3) {
    order: 3; } }

.page--offline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding: 80px 0;
  background-color: #191516; }
  .page--offline .wysi {
    color: white; }
    .page--offline .wysi h1,
    .page--offline .wysi h2,
    .page--offline .wysi h3 {
      color: white; }
    .page--offline .wysi a {
      color: #191516; }

.section--offline {
  width: 100%; }
  .section--offline .container {
    max-width: 768px;
    display: flex; }
  .section--offline .grid {
    margin: -20px; }
  .section--offline .col {
    padding: 20px; }
    .section--offline .col:first-child {
      display: flex; }

.offline__logo {
  display: block;
  width: 170px;
  margin: 8px 40px 0 0; }

.offline__newsletter {
  padding: 40px;
  background-color: #c4c4c4; }

@media screen and (max-width: 1023px) {
  .page--offline {
    padding: 40px 0; }
  .section--offline .col:first-child {
    flex-direction: column;
    align-items: center; }
  .offline__logo {
    margin: 0 0 40px 0; } }

/*
 * Theme overrides
 */
.section--404 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: black;
  opacity: 0.9; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: white;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: white; }

.mfp-preloader a:hover {
  color: white; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  font-size: 1.6rem;
  font-weight: 900;
  transition: opacity 0.2s ease; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
  transition: opacity 0.2s ease; }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #ccc; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: white;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #b3b3b3;
  font-size: 1.06667rem;
  line-height: 1.6rem;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.75;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.2s ease; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
  transition: opacity 0.2s ease; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 10px;
  border-bottom-width: 10px;
  top: 50%;
  margin-top: -10px; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 10px solid white; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 10px solid white; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: none;
  background: #000; }

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: none;
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 1.06667rem;
  line-height: 1.6rem; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.flatpickr-calendar {
  background: transparent;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  padding: 20px;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  position: absolute;
  width: auto;
  max-width: 480px;
  box-sizing: border-box;
  transition: top cubic-bezier(0, 1, 0.5, 1) 100ms;
  z-index: 99999999;
  background: #fff;
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08); }

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  max-height: 640px; }

.flatpickr-calendar.open {
  display: inline-block;
  animation: flatpickrFadeInDown 300ms cubic-bezier(0, 1, 0.5, 1); }

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px; }

.flatpickr-calendar.static {
  position: relative;
  top: 2px; }

.flatpickr-calendar.static.open {
  display: block; }

.flatpickr-calendar.hasWeeks {
  width: auto; }

.flatpickr-calendar.dateIsPicked.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6; }

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto; }

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px; }

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px; }

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px; }

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px; }

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%; }

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6; }

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff; }

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%; }

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6; }

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff; }

.flatpickr-month {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  text-align: center;
  user-select: none; }

.flatpickr-prev-month,
.flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  height: 16px;
  line-height: 16px; }

.flatpickr-prev-month i,
.flatpickr-next-month i {
  position: relative; }

.flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-next-month.flatpickr-prev-month {
  left: calc(3.57% - 1.5px); }

.flatpickr-prev-month.flatpickr-next-month,
.flatpickr-next-month.flatpickr-next-month {
  right: calc(3.57% - 1.5px); }

.flatpickr-prev-month:hover,
.flatpickr-next-month:hover {
  color: #959ea9; }

.flatpickr-prev-month:hover svg,
.flatpickr-next-month:hover svg {
  fill: #f64747; }

.flatpickr-prev-month svg,
.flatpickr-next-month svg {
  width: 14px; }

.flatpickr-prev-month svg path,
.flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit; }

.numInputWrapper {
  position: relative;
  height: auto; }

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block; }

.numInputWrapper input {
  width: 100%; }

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.05);
  box-sizing: border-box; }

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1); }

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2); }

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
  top: 33%; }

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0; }

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6); }

.numInputWrapper span.arrowDown {
  top: 50%; }

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6); }

.numInputWrapper span svg {
  width: inherit;
  height: auto; }

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5); }

.numInputWrapper:hover span {
  opacity: 1; }

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  width: 75%;
  left: 12.5%;
  top: 5px;
  display: inline-block;
  text-align: center; }

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  padding-left: 7px; }

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block; }

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: default;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: initial;
  border: 0;
  border-radius: 0;
  vertical-align: initial; }

.flatpickr-current-month input.cur-year:focus {
  outline: 0; }

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none; }

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden; }

.flatpickr-days,
.flatpickr-weeks {
  padding: 1px 0 0 0; }

.flatpickr-days {
  padding: 0 2.375px;
  outline: 0;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  height: 40px;
  line-height: 40px;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center; }

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6; }

.flatpickr-day.today {
  border-color: #ddd; }

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #f2f2f2;
  background: #f2f2f2; }

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #191516;
  color: #fff;
  border-color: #191516; }

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px; }

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0; }

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -3.75px 0 0 #e6e6e6, 3.75px 0 0 #e6e6e6; }

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
  pointer-events: none; }

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default; }

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  color: rgba(0, 0, 0, 0.54);
  height: 27.166666666666668px;
  line-height: 24px;
  margin: 0;
  background: transparent;
  text-align: center;
  display: block;
  float: left;
  width: 14.28%;
  font-weight: bold;
  margin: 0;
  padding-top: 3.166666666666667px; }

.rangeMode .flatpickr-day {
  margin-top: 1px; }

.flatpickr-weekwrapper {
  display: inline-block;
  float: left; }

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 1px 12px 0 12px;
  box-shadow: 1px 0 0 #e6e6e6; }

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%; }

.flatpickr-weekwrapper span.flatpickr-day {
  display: block;
  width: 100%;
  max-width: none; }

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  overflow: hidden; }

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box; }

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  -webkit-transition: height 0.33s cubic-bezier(0, 1, 0.5, 1);
  transition: height 0.33s cubic-bezier(0, 1, 0.5, 1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both; }

.flatpickr-time .numInputWrapper {
  flex: 1 1 0%;
  width: 40%;
  height: 40px;
  float: left; }

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939; }

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939; }

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%; }

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%; }

.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  cursor: pointer;
  color: #393939;
  font-size: 14px;
  position: relative;
  box-sizing: border-box; }

.flatpickr-time input.flatpickr-hour {
  font-weight: bold; }

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400; }

.flatpickr-time input:focus {
  outline: 0;
  border: 0; }

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400; }

.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #f0f0f0; }

.hasWeeks .flatpickr-days,
.hasTime .flatpickr-days {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.hasWeeks .flatpickr-days {
  border-left: 0; }

@media all and (-ms-high-contrast: none) {
  .flatpickr-month {
    padding: 0; }
  .flatpickr-month svg {
    top: 0 !important; } }

@media screen and (max-width: 479px) {
  .flatpickr-calendar {
    max-width: calc(100% - 60px); } }

@-webkit-keyframes flatpickrFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes flatpickrFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }
