.header{
	width: 100%;
}

.header__logo{
	display: block;
	margin: 50px 0 0;
	width: 200px;
	height: 164px;
}

.header--primary{
	position: relative;
	z-index: 1;

	.container{
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.header__btn--email{
	position: absolute;
	top: 100px;
	right: $gutter*1.5;
	display: block;
	width: 60px;
	height: 60px;
	border-radius: 30px;
	background-color: $color-1;

	.icon{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 20px;
		height: 20px;
		fill: white;
	}
}

.nav--mobile{
	display: none;
}

.nav--primary{
	flex: 1 0 auto;

	ul{
		padding: 0;
		margin: 0;
		display: flex;
		justify-content: space-between;
	}

	li{
		display: inline-flex;
	}

	a{
		display: flex;
		font-size: rem(13);
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		transition: all 0.1s ease;

		&:hover{
			color: $font-color-1;
		}
	}

	.is-active a{
		font-weight: 900;
		color: $font-color-1;
	}
}

.nav--languages{
	position: relative;

	ul{
		opacity: 0;
		padding: 0;
		margin: 0;
		flex-direction: column;

		.nav-languages-is-active &{
			opacity: 1;
			transition: opacity 0.2s ease;
		}
	}

	li{
		display: inline-flex;
	}

	a{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		font-size: rem(13);
		font-weight: 900;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		color: white;
		transition: all 0.1s ease;

		&:not(.nav__toggle--languages){
			background-color: $color-3;

			&:hover{
				background-color: lighten($color-3, 5);
			}
		}
	}
}

.nav__toggle--languages{
	background-color: $color-2;

	&:hover{
		background-color: lighten($color-2, 5);
	}
}

.nav__toggle--languages + ul{
	position: absolute;
	z-index: 1;
	top: 100%;
	left: 0;
}

.header--alert{
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	min-height: 70px;
	padding: $gutter $gutter*1.5;
	background-color: $color-1;

	h1,
	h2,
	h3{
		color: white;
	}

	.wysi{
		color: white;
	}
}

.alert__btn--close{
	width: 70px;
	height: 70px;
	position: absolute;
	top: 0;
	right: 0;
	overflow: hidden;
	text-indent: -999em;

	&:before,
	&:after{
		position: absolute;
		top: 50%;
		left: 50%;
		content: '';
		display: block;
		width: 20px;
		height: 2px;
		background-color: white;
	}

	&:before{
		transform: rotate(45deg);
	}

	&:after{
		transform: rotate(-45deg);
	}
}

@media screen and (max-width: 1199px){

}

@media screen and (max-width: 1023px){
	.header__logo{
		width: 150px;
		height: 123px;
	}
}

@media screen and (max-width: 959px){

}

@media screen and (max-width: 767px){

}

@media screen and (max-width: 639px){

}

@media screen and (max-width: 479px){
	
}