.btn{
	display: inline-flex !important;
	padding: 17px 36px !important;
	font-size: rem(13) !important;
	font-weight: 900 !important;
	text-transform: uppercase !important;
	text-decoration: none !important;
	text-align: center !important;
	line-height: rem(17) !important;
	letter-spacing: 0.2em !important;
	color: white !important;
	border-radius: 999em !important;
	transition: all 0.2s ease !important;

	&--color-1{
		background-color: $color-1;

		&:hover{
			background-color: darken($color-1, 10);
		}
	}

	&--color-2{
		background-color: $color-2;

		&:hover{
			background-color: darken($color-2, 10);
		}
	}

	&--color-3{
		background-color: $color-3;

		&:hover{
			background-color: darken($color-3, 10);
		}
	}
}