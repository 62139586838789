.footer{
	overflow: hidden;
	padding: $gutter*4 0;
	background-color: $color-1;

	hr{
		display: flex;
		border-top: 1px solid lighten($color-1, 10);
		width: 100%;
		height: 0;
	}

	.grid{
		align-items: flex-start;
		margin: -$gutter;
	}

	.col{
		padding: $gutter;

		&:last-child{
			display: flex;
			justify-content: space-between;
		}
	}

	.wysi{
		color: white;

		h1,
		h2,
		h3{
			color: white;
		}
	}
}

.footer__logo{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 50px;
	background-color: $color-1;
	display: block;
	width: 220px;
	height: 198px;
}

.footer__copyrights{
	display: inline-flex;
	font-size: rem(11);
	color: darken(white, 50);
}

.footer__credits{
	display: inline-flex;
	font-size: rem(11);
	color: darken(white, 50);

	a{
		transition: all 0.1s ease;
		
		&:hover{
			color: white;
		}
	}
}

.nav--social{
	width: 100%;

	ul{
		width: 100%;
		display: flex;
		justify-content: flex-end;
		padding: 0;
		margin: 0;
	}

	li{
		display: inline-flex;
		margin: 0 2px;

		&:first-child{
			margin-left: 0;
		}

		&:last-child{
			margin-right: 0;
		}
	}

	a{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		transition: all 0.1s ease;

		&:hover{
			background-color: $color-1;
		}
	}

	.icon{
		width: 18px;
		height: 18px;
		fill: white;
	}
}

.nav--footer{
	ul{
		display: flex;
		padding: 0;
		margin: 0;
		flex-direction: column;
	}

	li{
		display: inline-flex;
	}

	a{
		color: white;
		transition: all 0.1s ease;

		&:hover{
			color: $color-1;
		}
	}

	.is-active{
		a{
			font-weight: bold;
			color: $color-1;
		}
	}
}

.form--newsletter{
	margin-top: 1.5em;

	::placeholder{
		font-size: rem(14);
		font-weight: bold;
		color: white;
	}

	input,
	textarea{
		border-color: lighten($color-2, 15);
		color: white;

		&:focus{
			border-color: $color-1;
		}
	}

	.error input{
		border-color: lighten($error, 10);
	}

	.success input{
		border-color: lighten($success, 10);
	}
}

@media screen and (max-width: 1199px){

}

@media screen and (max-width: 1023px){
	.footer{
		padding: 40px 0;

		hr{
			margin: 0;
		}
	}

	.footer__logo{
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		margin: 0 auto;
		padding: 0;
		background-color: transparent;
		width: 150px;
		height: 123px;
		margin-bottom: 50px;
	}

	.nav--social{
		ul{
			justify-content: center;
		}
	}

	.footer{
		.col{
			&:last-child{
				flex-direction: column;
				align-items: center;
			}
		}
	}
}

@media screen and (max-width: 959px){

}

@media screen and (max-width: 767px){
	
}

@media screen and (max-width: 639px){

}

@media screen and (max-width: 479px){
	
}