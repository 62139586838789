.section--blocks{
	width: 100%;
	overflow: hidden;

	.container{
		display: flex;
		flex-direction: column;
	}

	.block--image-left{
		display: flex;
		margin: 0 calc(50% - 50vw);
		background-color: $color-1;

		.block__image{
			width: 50%;
			min-height: 600px;
			order: 0;
			background-position: center center;
			background-size: cover;
		}

		.block__text{
			display: flex;
			align-items: center;
			width: 50%;
			order: 1;
		}

		.wysi{
			padding: 120px;
			color: white;

			h1,
			h2,
			h3{
				color: white;
			}

			strong{
				color: white;
			}
		}
	}

	.block--image-right{
		display: flex;
		margin: 0 calc(50% - 50vw);
		background-color: $color-1;

		.block__image{
			width: 50%;
			min-height: 600px;
			order: 2;
			background-position: center center;
			background-size: cover;
		}

		.block__text{
			display: flex;
			align-items: center;
			width: 50%;
			order: 1;
		}

		.wysi{
			padding: 120px;
			color: white;

			h1,
			h2,
			h3{
				color: white;
			}

			strong{
				color: white;
			}
		}
	}
}

@media screen and (max-width: 1199px){
	.section--blocks{
		.block--image-right,
		.block--image-left{
			.wysi{
				padding: 60px;
			}
		}
	}
}

@media screen and (max-width: 1023px){
	.section--blocks{
		.block--image-right{
			flex-wrap: wrap;

			.block__image{
				width: 100%;
			}

			.block__text{
				width: 100%;
			}

			.wysi{
				width: 100%;
			}
		}

		.block--image-left{
			flex-wrap: wrap;

			.block__image{
				width: 100%;
				order: 2;
			}

			.block__text{
				width: 100%;
				order: 1;
			}

			.wysi{
				width: 100%;
				padding: 60px;
			}
		}
	}
}

@media screen and (max-width: 959px){

}

@media screen and (max-width: 767px){
	.section--blocks{
		.block--image-left,
		.block--image-right{
			.block__image{
				min-height: 480px;
			}
		}
	}
}

@media screen and (max-width: 639px){

}

@media screen and (max-width: 479px){
	
}